import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { formPostHandler } from "../dal/apiRequest";
import CustomSelect from '../components/Shared/customSelect';
import RecaptchaText from "../components/Shared/recaptchaText";

function BecomeTesterForm() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const formik = useFormik({
        initialValues: {
            full_name: '',
            email: '',
            game: '',
            platform: '',
            device_model: ''
        },
        validationSchema: Yup.object({
            full_name: Yup.string()
                .required('Required'),
            game: Yup.string()
                .required('Required'),
            platform: Yup.string()
                .required('Required'),
            device_model: Yup.string()
                .required('Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
        }),
        onSubmit: async (values, actions) => {
            const token = await executeRecaptcha("submit");
            values.token = token;
            const success = await formPostHandler("tester-forms", values, "Your request has been received. We will get back to you about the subject as soon as possible.")

            if (success) {
                actions.resetForm({
                    values: {
                        full_name: '',
                        email: '',
                        game: '',
                        platform: '',
                        device_model: ''
                    },
                })
            } else {
                actions.resetForm({
                    values: {
                        full_name: values.full_name,
                        email: values.email,
                        game: values.game,
                        platform: values.platform,
                        device_model: values.device_model
                    },
                })
            }
            actions.setSubmitting(false);
        },
    });
    return (
        <form className="form--primary" onSubmit={formik.handleSubmit}>

            <div className="form-group">
                <label htmlFor="name">Your Full Name</label>
                <input
                    className="form-control style--two"
                    id="name"
                    type="text"
                    name="full_name"
                    placeholder="Enter Your Full Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.full_name}
                />
                {formik.touched.full_name && formik.errors.full_name ? (
                    <label className="error">{formik.errors.full_name}</label>
                ) : null}
            </div>

            <div className="form-group">
                <label htmlFor="email">Your Email</label>
                <input
                    className="form-control style--two"
                    id="email"
                    type="text"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                    <label className="error">{formik.errors.email}</label>
                ) : null}
            </div>

            {/* <div className="form-group nice-select-group">
                <label>Select Game</label>
                <select
                    className="nice-select"
                    name="game"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.game}
                >
                    <option
                        value=""
                        data-display="--- Please choose an option ---"
                    >
                        --- Please choose an option ---
                      </option>
                    <option value="no_surrender">No Surrender</option>
                </select>
                {formik.touched.game && formik.errors.game ? (
                    <label className="error">{formik.errors.game}</label>
                ) : null}
            </div> */}
            <div className="form-group">
                <label>Select Game</label>
                <CustomSelect
                    options={[
                        { value: "no_surrender", label: "No Surrender" }
                    ]}
                    fieldName={"game"}
                    onChange={async (e) => {
                        await formik.setFieldValue("game", e.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.game}
                />
                {formik.touched.game && formik.errors.game ? (
                    <label className="error">{formik.errors.game}</label>
                ) : null}
            </div>

            {/* <div className="form-group nice-select-group">
                <label>Platform</label>
                <select
                    className="nice-select"
                    name="platform"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.platform}
                >
                    <option
                        value
                        data-display="--- Please choose an option ---"
                    >
                        --- Please choose an option ---
                      </option>
                    <option value="ipad_iphone">Apple iPad or iPhone</option>
                    <option value="android">Android</option>
                    <option value="windows_phone_8">Windows Phone 8</option>
                    <option value="pc_mac">PC/Mac</option>
                </select>
                {formik.touched.platform && formik.errors.platform ? (
                    <label className="error">{formik.errors.platform}</label>
                ) : null}
            </div> */}

            <div className="form-group">
                <label>Platform</label>
                <CustomSelect
                    options={[
                        { value: "ipad_iphone", label: "Apple iPad or iPhone" },
                        { value: "android", label: "Android" },
                        { value: "windows_phone_8", label: "Windows Phone 8" },
                        { value: "pc_mac", label: "PC/Mac" }
                    ]}
                    fieldName={"platform"}
                    onChange={async (e) => {
                        await formik.setFieldValue("platform", e.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.platform}
                />
                {formik.touched.platform && formik.errors.platform ? (
                    <label className="error">{formik.errors.platform}</label>
                ) : null}
            </div>

            <div className="form-group">
                <label htmlFor="deviceModel">Device Model</label>
                <input
                    className="form-control style--two"
                    id="deviceModel"
                    type="text"
                    name="device_model"
                    placeholder="Enter Your Device Model"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.device_model}
                />
                {formik.touched.device_model && formik.errors.device_model ? (
                    <label className="error">{formik.errors.device_model}</label>
                ) : null}
            </div>

            <div className="mb-3">
                <button
                    className="submit-btn"
                    type="submit"
                >
                    Send
                </button>
            </div>
            <RecaptchaText />
            {/* <span className="mr-1">
                This site is protected by reCAPTCHA and the Google{" "}
            </span>
            <a
                className="mr-1"
                href="https://policies.google.com/privacy"
            >
                Privacy Policy
                  </a>
            <span className="mr-1">and</span>
            <a className="mr-1" href="https://policies.google.com/terms">
                Terms of Service
                  </a>
            <span>apply.</span> */}
        </form>
    );
}

export default BecomeTesterForm
