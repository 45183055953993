import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Parallax } from "react-scroll-parallax"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Home/hero"
import BecomeTesterForm from "../forms/becomeTesterForm"
import VideoBox from "../components/AboutUs/videoBox"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <section className="pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="section-header text-center has--bg">
                <div className="header-image">
                  <img src="elements/header-el.png" alt="no surrender"></img>
                  {/* <StaticImage
                    src="../images/elements/header-el.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="no surrender"
                  /> */}
                </div>
                <h2 className="section-title">Top Games</h2>
              </div>
            </div>
          </div>
          <div className="row mb-none-40">
            <div className="col-lg-12 mb-40 single-game">
              <div className="game-card style--two ">
                <div
                  className="game-card__content style--special wow fadeInRight"
                  style={{ position: "relative", zIndex: 200 }}
                  data-wow-duration="0.5s"
                  data-wow-delay="0.7s"
                >
                  <div className="game-card__details">
                    <div className="game-card__info">
                      <div className="thumb-wrapper">
                        <div className="thumb">
                          <img src="games/s4-new.png" alt="no surrender" />
                        </div>
                        <div className="game-card__ratings">
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star-half-alt" />
                          <span>(4.8)</span>
                        </div>
                      </div>
                      <div className="content">
                        <h3 className="game-card__title">
                          <a href="game-details.html">NO Surrender HEROES</a>
                        </h3>
                        <p>
                          No Surrender Heroes is a free to play real-time
                          strategy game with a deep experience level.
                        </p>
                        <Link
                          className="mt-3"
                          to="https://nosurrenderheroes.io/"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="game-card__thumb wow zoomIn"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.3s"
                >
                  <div style={{ position: "relative", zIndex: 100 }}>
                    <img src="games/heroes_kv_01-new.webp" alt="no surrender" />
                    {/* <VideoBox holderstyle={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", marginTop: "0 !important" }}></VideoBox> */}
                  </div>
                </div>
                {/* <div
                  className="game-card__footer wow fadeInUp"
                  style={{ position: "relative", zIndex: 200, marginTop: 24 }}
                  data-wow-duration="0.5s"
                  data-wow-delay="0.9s"
                >
                  <div className="left">
                    <div className="thumb">
                      <img
                        className="w-100"
                        src="games/1242x2688bb_(1).webp"
                        alt="no surrender"
                      />
                    </div>
                    <div className="thumb">
                      <img
                        className="w-100"
                        src="games/m2.jpg"
                        alt="no surrender"
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="caption">DOWNLOAD NOW AT</div>
                    <div className="apps-btn">
     
                      <img src="elements/apple-btn.png" alt="no surrender" />
              
                      <img src="elements/google-btn.png" alt="no surrender" />
        
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-12 mb-40 single-game">
              <div className="game-card style--two">
                <div
                  className="game-card__content wow fadeInRight"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.7s"
                >
                  <div className="game-card__details">
                    <div className="game-card__info">
                      <div className="thumb-wrapper">
                        <div className="thumb">
                          <img src="games/s4.jpg" alt="no surrender" />
                        </div>
                        <div className="game-card__ratings">
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star" />
                          <i className="fas fa-star-half-alt" />
                          <span>(4.5)</span>
                        </div>
                      </div>
                      <div className="content">
                        <h3 className="game-card__title">
                          <a href="game-details.html">Quod nihil</a>
                        </h3>
                        <p>Repellendus temporibus suscipit voluptate eius.</p>
                        <a className="mt-3" href="game-details.html">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="game-card__thumb wow zoomIn"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.3s"
                >
                  <img src="games/b4.jpg" alt="no surrender" />
                </div>
                <div
                  className="game-card__footer wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.9s"
                >
                  <div className="left">
                    <div className="thumb">
                      <img className="w-100" src="games/m1.jpg" alt="no surrender" />
                    </div>
                    <div className="thumb">
                      <img className="w-100" src="games/m2.jpg" alt="no surrender" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="caption">DOWNLOAD NOW AT</div>
                    <div className="apps-btn">
                      <a href="coming-soon.html">
                        <img src="elements/apple-btn.png" alt="no surrender" />
                      </a>
                      <a href="coming-soon.html">
                        <img src="elements/google-btn.png" alt="no surrender" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="pt-120 pb-120 position-relative overflow-hidden home__becomeATester">
        <Parallax className="about-obj-1" x={[-20, 20]}>
          <div
            data-paroller-factor="-0.08"
            data-paroller-type="foreground"
            data-paroller-direction="horizontal"
          >
            <img src="elements/about-obj-1.png" alt="no surrender" />
          </div>
        </Parallax>

        <Parallax className="about-obj-2" x={[20, -20]}>
          <div
            data-paroller-factor="0.08"
            data-paroller-type="foreground"
            data-paroller-direction="horizontal"
          >
            <img src="elements/about-obj-2.png" alt="no surrender" />
          </div>
        </Parallax>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-header text-center has--bg">
                <div className="header-image">
                  <img src="elements/header-el.png" alt="no surrender" />
                </div>
                <h2 className="section-title">Become a tester</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 offset-lg-1 mt-lg-0 mt-4 d-flex align-items-center home__becomeATester__leftCol">
              <div className="about-thumb">
                <img
                  className="image-1"
                  src="elements/about-player.png"
                  alt="no surrender"
                />
                <img
                  className="image-2"
                  src="elements/about-phone.png"
                  alt="no surrender"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="formBox">
                <BecomeTesterForm></BecomeTesterForm>
                {/* <form className="form--primary" id="becomeATesterForm">
                  <div className="form-group">
                    <label htmlFor="name">Your Full Name</label>
                    <input
                      className="form-control style--two"
                      id="name"
                      type="text"
                      name="full_name"
                      placeholder="Enter Your Full Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Your Email</label>
                    <input
                      className="form-control style--two"
                      id="email"
                      type="text"
                      name="email"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="form-group nice-select-group">
                    <label>Select Game</label>
                    <select className="nice-select" name="game">
                      <option
                        value
                        data-display="--- Please choose an option ---"
                      >
                        --- Please choose an option ---
                      </option>
                      <option value="no_surrender">No Surrender</option>
                    </select>
                  </div>
                  <div className="form-group nice-select-group">
                    <label>Platform</label>
                    <select className="nice-select" name="platform">
                      <option
                        value
                        data-display="--- Please choose an option ---"
                      >
                        --- Please choose an option ---
                      </option>
                      <option value="ipad_iphone">Apple iPad or iPhone</option>
                      <option value="android">Android</option>
                      <option value="windows_phone_8">Windows Phone 8</option>
                      <option value="pc_mac">PC/Mac</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="deviceModel">Device Model</label>
                    <input
                      className="form-control style--two"
                      id="deviceModel"
                      type="text"
                      name="device_model"
                      placeholder="Enter Your Device Model"
                    />
                  </div>
                  <div className="mb-3">
                    <button
                      className="submit-btn"
                      id="becomeATesterFormSubmitBtn"
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                  <span className="mr-1">
                    This site is protected by reCAPTCHA and the Google{" "}
                  </span>
                  <a
                    className="mr-1"
                    href="https://policies.google.com/privacy"
                  >
                    Privacy Policy
                  </a>
                  <span className="mr-1">and</span>
                  <a className="mr-1" href="https://policies.google.com/terms">
                    Terms of Service
                  </a>
                  <span>apply.</span>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-120 pb-120 home__carrers">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 home__carrers__leftCol">
              <div className="section-header has--bg mb-5">
                <div className="header-image justify-content-start">
                  <img src="elements/header-el-7.png" alt="no surrender" />
                </div>
                <h2 className="section-title">Careers</h2>
              </div>
              <p>
                We believe, the future of gaming involves the gaming community
                and social interaction amongst game lovers.
              </p>
              <p>
                Passion in our ambition. Challenge is our drive . Be a part of
                this journey.
              </p>
              <p>We don’t just make games but we share exciting experiences.</p>
              <p>We drive to match the futures needs.</p>
              <p>Be a part our team.</p>
              <p>Are you passionate to play?</p>
              <Link className="cmn-btn mt-5" to="/careers">
                Apply now
              </Link>
            </div>
            <div className="col-lg-4 mt-lg-0 mt-4">
              <div className="game-info-thumb d-flex justify-content-center">
                <img
                  src="elements/careers/careers.png"
                  alt="Carrer | NoSurrender"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-120 pb-50 position-relative overflow-hidden">
        <Parallax className="about-obj-1" x={[-20, 20]}>
          <div
            data-paroller-factor="-0.08"
            data-paroller-type="foreground"
            data-paroller-direction="horizontal"
          >
            <img src="elements/about-obj-1.png" alt="no surrender" />
          </div>
        </Parallax>
        <Parallax className="about-obj-2" x={[20, -20]}>
          <div
            data-paroller-factor="0.08"
            data-paroller-type="foreground"
            data-paroller-direction="horizontal"
          >
            <img src="elements/about-obj-2.png" alt="no surrender" />
          </div>
        </Parallax>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header text-center has--bg">
                <div className="header-image">
                  <img src="elements/header-el-6.png" alt="no surrender" />
                </div>
                <h2 className="section-title">CONTACT US</h2>
                <p>We love to hear from you!</p>
                <p>Let us know what you need and how can we help.</p>
                {/* <a className="cmn-btn style--two mt-5" href="support.html">
                  Contact Us
                </a> */}
                <Link className="cmn-btn style--two mt-5" to="/support">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
