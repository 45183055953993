import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ParallaxImage from "../Shared/parallaxImage"
import useMove from "../../hooks/useMove"

function Hero() {
  const { pageX, pageY, handleMouseMove } = useMove()
  const heroRef = useRef()

  return (
    <section
      className="hero bg_img"
      onMouseMove={handleMouseMove}
      ref={heroRef}
    >
      <div className="hero__shape">
        <img src="elements/hero/shape-2.png" alt="no surrender"></img>
      </div>

      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-45}
        heroRef={heroRef}
        name={"/hero/el-1.png"}
        classes={"el-1"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-25}
        heroRef={heroRef}
        name={"/hero/el-2.png"}
        classes={"el-2"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-50}
        heroRef={heroRef}
        name={"/hero/el-3.png"}
        classes={"el-3"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-35}
        heroRef={heroRef}
        name={"/hero/el-4.png"}
        classes={"el-4"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-60}
        heroRef={heroRef}
        name={"/hero/el-5.png"}
        classes={"el-5"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-40}
        heroRef={heroRef}
        name={"/hero/el-6.png"}
        classes={"el-6"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-45}
        heroRef={heroRef}
        name={"/hero/el-7.png"}
        classes={"el-7"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-35}
        heroRef={heroRef}
        name={"/hero/el-8.png"}
        classes={"el-8"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-50}
        heroRef={heroRef}
        name={"/hero/el-9.png"}
        classes={"el-9"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-35}
        heroRef={heroRef}
        name={"/hero/el-10.png"}
        classes={"el-10"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-40}
        heroRef={heroRef}
        name={"/hero/el-11.png"}
        classes={"el-11"}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="hero__content">
              {/* <span
                className="hero__sub-title wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              >
                NO SURRENDER
              </span> */}
              <h2
                className="hero__title wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.5s"
              >
                THE FUTURE OF GAMING
              </h2>
              <p
                className="wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.7s"
              >
                Our games entertain millions of people improving  the experiences we plan to accomplish  through our technology.<br/>
                We will continue to unfold  the  gaming experiences with our new features.
              </p>
              <Link
                className="cmn-btn wow fadeInUp"
                to="/games"
                data-wow-duration="0.5s"
                data-wow-delay="0.9s">
                GAMES
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Hero
